/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import GameBoard from "./GameBoard";
import { getRoomState, makeMove } from "../api/room";
import "../styles/game.css";

function GamePage() {
  const [gameState, setGameState] = useState(
    [...Array(5)].map(() => Array(5).fill(null))
  );
  const [playerTurn, setPlayerTurn] = useState(null);
  const [moveHistory, setMoveHistory] = useState([]);
  const [winner, setWinner] = useState(null);
  const [logs, setLogs] = useState([]);
  const [roomCode, setRoomCode] = useState("");
  const [ws, setWs] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const roomId = Cookies.get("roomId");
    const storedRoomCode = Cookies.get("roomCode");
    const token = Cookies.get("token");

    if (!token) {
      navigate("/");
      return;
    }

    if (!roomId) {
      navigate("/rooms");
      return;
    }

    if (storedRoomCode) {
      setRoomCode(storedRoomCode);
    }

    // Fetch the room state from the backend
    const fetchRoomState = async () => {
      try {
        const { room, logs } = await getRoomState(roomId);
        setGameState(JSON.parse(room.boardState));
        setPlayerTurn(
          room.currentPlayer === room.player1Id ? "Player A" : "Player B"
        );
        setLogs(logs);
      } catch (error) {
        console.error("Failed to fetch room state:", error);
        navigate("/rooms");
      }
    };

    fetchRoomState();

    const socket = new WebSocket("wss://hitwicket.azurewebsites.net");

    socket.onopen = () => {
      setWs(socket);
    };

    socket.onmessage = (message) => {
      const data = JSON.parse(message.data);
      if (data.type === "UPDATE_BOARD" && data.roomId === roomId) {
        fetchRoomState();
      }
    };

    return () => {
      socket.close();
    };
  }, [navigate]);

  const handleMove = async (x, y) => {
    try {
      const roomId = Cookies.get("roomId");
      const move = { x, y };
      const result = await makeMove(roomId, move);

      setGameState(JSON.parse(result.boardState));
      setMoveHistory([...moveHistory, `${playerTurn}: Moved to (${x}, ${y})`]);

      if (result.winner) {
        setWinner(playerTurn);
      } else {
        setPlayerTurn(playerTurn === "Player A" ? "Player B" : "Player A");
      }
    } catch (error) {
      console.error("Failed to make move:", error);
    }
  };

  const handleLogout = () => {
    Cookies.remove("token");
    Cookies.remove("roomId");
    Cookies.remove("roomCode");
    Cookies.remove("userId");
    navigate("/");
  };

  return (
    <div className="game-page-container">
      <h1>Advanced Chess-like Game</h1>
      {winner && <div className="winner-banner">{winner} wins!</div>}
      <div className="room-code">
        <strong>Room Code:</strong> {roomCode}
      </div>
      <GameBoard
        gameState={gameState}
        setBoardState={setGameState}
        onMove={handleMove}
        currentPlayerId={Cookies.get("userId")}
      />
      <div className="move-history">
        <h2>Move History</h2>
        <ul>
          {moveHistory.map((move, index) => (
            <li key={index}>{move}</li>
          ))}
        </ul>
      </div>
      <div className="game-logs">
        <h2>Game Logs</h2>
        <ul>
          {logs.map((log, index) => (
            <li key={index}>
              {log.character} moved from ({log.from.rowIndex},{" "}
              {log.from.cellIndex}) to ({log.to.rowIndex}, {log.to.cellIndex})
            </li>
          ))}
        </ul>
      </div>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
}

export default GamePage;
